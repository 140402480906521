import { Icon } from "@mui/material";
import React from "react";

const FullimageComponent= (props) =>{
    const {src,onChangeBack,onChangeForward} = props;
    return <div className="FullImageArea">
        <div className="BackButton" onClick={onChangeBack}>
            <Icon>arrow_back_icon</Icon>
        </div>
        <img className="FullImage disable-select" src={src} />
        <div className="ForwardButton" onClick={onChangeForward}>
            <Icon>arrow_forward_icon</Icon>
        </div>

    </div>
}

export default FullimageComponent;